import ApiConfig from './ApiConfig'
import { FormSiteClient_V1, FormStyleClient_V1, PatientFormClient_V1 } from './generated/api.generated.v1'
import variables from '@/variables'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // Form Site
    fetchForms() {
      const api = new FormSiteClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getSiteForms()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    //
    fetchFormConfig(context, formStyleId) {
      const api = new FormStyleClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getFormStyle(formStyleId)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    postFormConfig(context, formStyleDTO) {
      // const userData = JSON.parse(localStorage.getItem('userData')) // Uncomment

      console.log('postFormConfig', formStyleDTO)

      const api = new FormStyleClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .postFormStyle(formStyleDTO)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // Patient Form

    fetchPatientForms(context, patientId) {
      const api = new PatientFormClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getPatientForms(patientId)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchRecentPatientForms(context, payload) {
      const api = new PatientFormClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getRecentPatientForms(payload.patientId, payload.formName)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    postPatientForm(context, patientFormDTO) {
      const api = new PatientFormClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .postPatientForm(patientFormDTO)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    postPatientFormDraft(context, patientFormDTO) {

      const api = new PatientFormClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .postPatientFormDraft(patientFormDTO)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deletePatientForm(context, patientFormId) {
      const api = new PatientFormClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .deletePatientForm(patientFormId)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // Patient Form Addendum

    fetchPatientFormAddendums(context, patientFormId) {
      const api = new PatientFormClient_V1(new ApiConfig(), variables.INTERNAL_API)
        console.log(patientFormId)
      return new Promise((resolve, reject) => {
        api
          .getPatientFormAddendums(patientFormId)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    postPatientFormAddendum(context, patientFormAddendumDTO) {
      // const userData = JSON.parse(localStorage.getItem('userData')) // Uncomment


      const api = new PatientFormClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .postPatientFormAddendum(patientFormAddendumDTO)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // Patient Document
    fetchPatientPDFs(context, payload) {
      const api = new PatientFormClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getPatientPDFs(payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchPatientPDF(context, payload) {
      const api = new PatientFormClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getPatientPDF(payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    postPatientPDF(context, payload) {
      const api = new PatientFormClient_V1(new ApiConfig(), variables.INTERNAL_API)

      console.log('service', payload)

      return new Promise((resolve, reject) => {
        api
          .postPatientPDF(payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

        // Patient TB
        fetchPatientTBForms(context, payload) {
          const api = new PatientFormClient_V1(new ApiConfig(), variables.INTERNAL_API)

          return new Promise((resolve, reject) => {
            api
              .getPatientTBForms(payload)
              .then(response => resolve(response))
              .catch(error => reject(error))
          })
        },

        fetchPatientTBForm(context, payload) {
          const api = new PatientFormClient_V1(new ApiConfig(), variables.INTERNAL_API)

          return new Promise((resolve, reject) => {
            api
              .getPatientTBForm(payload)
              .then(response => resolve(response))
              .catch(error => reject(error))
          })
        },

        postPatientTBForm(context, payload) {
          const api = new PatientFormClient_V1(new ApiConfig(), variables.INTERNAL_API)

          console.log('service', payload)

          return new Promise((resolve, reject) => {
            api
              .postPatientTBForm(payload)
              .then(response => resolve(response))
              .catch(error => reject(error))
          })
        },
  },
}
