<template>
  <div>
    <v-dialog
      v-if="renderDialog"
      v-model="renderDialog"
      :persistent="isEdit"
      max-width="95%"
    >
      <div>
        <v-card>
          <report-render
            v-model="renderDialog"
            :data="reportData"
            :source="reportSiteDTO.dataSources"
            :report-style="reportStyle"
            :is-edit="isEdit"
            :name="reportSiteDTO.reportName"
            :form="formName"
            @update-dialog="updateDialog"
            @report-config="updateReport"
          ></report-render>
        </v-card>
      </div>
    </v-dialog>

    <v-dialog
      v-model="isReportFormActive"
      persistent
      max-width="75%"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Report Details</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="ReportForm"
          >
            <v-row>
              <v-col
                cols="12"
                lg="6"
              >
                <task-type-select
                  :multiple="false"
                  :task-type="(reportSiteDTO.reportGroup == undefined || reportSiteDTO.reportGroup == null) ? 'Medical' : reportSiteDTO.reportGroup"
                  :readonly="!isEdit"
                  @task-type-select="updateType"
                ></task-type-select>
              </v-col>

              <v-col
                cols="12"
                lg="2"
              >
                <v-switch
                  v-model="reportSiteDTO.isActive"
                  color="primary"
                  :value="reportSiteDTO.isActive != null ? reportSiteDTO.isActive : true"
                  :label="reportSiteDTO.isActive ? 'Active' : 'Inactive'"
                  :readonly="!isEdit"
                >
                </v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                lg="6"
              >
                <v-text-field
                  v-model="reportSiteDTO.reportName"
                  label="Report Name"
                  hint="Active Meds per Patient"
                  outlined
                  dense
                  :readonly="!isEdit"
                  :rules="[required]"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                lg="6"
              >
                <v-text-field
                  v-model="reportSiteDTO.reportDescription"
                  label="Report Description"
                  hint="A list of all patient with active Medications and the list of medications"
                  outlined
                  dense
                  :readonly="!isEdit"
                  :rules="[required]"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                lg="6"
              >
                <data-source-select
                  @source-select="updateSource"
                ></data-source-select>
              </v-col>

              <v-col
                cols="12"
                lg="6"
              >
                <form-select
                  @form-select="updateForm"
                ></form-select>
              </v-col>
            </v-row>


            <v-row>
              <v-col class="text-right">
                <v-btn
                  color="error darken-1"
                  type="reset"
                  text
                  @click="closeForm()"
                >
                  Close
                </v-btn>
                <v-btn
                  color="primary darken-1"
                  text
                  @click="buildReport(reportSiteDTO)"
                >
                  Continue
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import store from '@/store'
import { required } from '@core/utils/validation'
import { ref, onUnmounted } from '@vue/composition-api'
import reportService from '@/services/ReportService'
import useReportService from '@/store/useReportService'
import TaskTypeSelect from '@/components/task/TaskTypeSelect.vue'
import DataSourceSelect from '@/components/report/DataSourceSelect.vue'
import ReportRender from '@/apps/report/ReportRender.vue'
import moment from 'moment'

import FormSelect from '@/components/patient/FormSelect.vue'

export default {
  components: {
    TaskTypeSelect,
    DataSourceSelect,
    ReportRender,
    FormSelect,
  },
  model: {
    prop: 'isReportFormActive',
    event: 'update:is-report-form-active',
  },
  props: {
    isReportFormActive: {
      type: Boolean,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
    reportSiteDTO: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    console.log('Report', props.reportSiteDTO)
    const REPORT_APP = 'app-report'

    // Register module
    if (!store.hasModule(REPORT_APP)) {
      store.registerModule(REPORT_APP, reportService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REPORT_APP)) {
        store.unregisterModule(REPORT_APP)
      }
    })

    const { postReport, reportData, fetchDataSourceData, dateRangeList, fetchSiteReport, singleReport } = useReportService()

    const reportForm = ref(null)
    const reportStyle = ref({})
    const renderDialog = ref(false)
    const formName = ref(null)

    const refreshParent = () => {
      emit('refetch-data')
    }

    const closeForm = () => {
      emit('update-dialog', 'edit')
    }

    return {
      reportForm,
      closeForm,
      refreshParent,
      required,
      renderDialog,
      reportStyle,

      postReport,
      fetchDataSourceData,
      reportData,
      dateRangeList,
      formName,
      fetchSiteReport,
      singleReport,
    }
  },
  created() {
    console.log('Report Add Mounted', this.reportSiteDTO)
    if (this.reportSiteDTO.reportName != null && this.reportSiteDTO.reportName != undefined) {
      this.buildReport()
    }
    else{
      this.reportSiteDTO.dateRange = 'Day'
      this.reportSiteDTO.isActive = true
    }
    console.log('Report Add Mounted', this.reportSiteDTO)
  },
  methods: {
    validate() {
      if (this.reportForm.value.validate(this.reportSiteDTO)) {
        return true
      }

      return false
    },
    updateDialog(dialog) {
      console.log('Dialog', this.editDialog, this.renderDialog)
      if (dialog === 'edit') {
        this.isEdit = true
        if (this.editDialog) {
          this.selectedReport = {}
        }
        this.editDialog = !this.editDialog
      }
      else {
        this.renderDialog = !this.renderDialog
      }
      console.log('Selected Form', this.selectedReport)
    },
    submitForm() {
      console.log('posting', this.reportSiteDTO)

      this.postReport(this.reportSiteDTO)

      this.closeForm()
      this.refreshParent()
    },
    buildReport() {
      console.log('building', this.reportSiteDTO)

      this.fetchSiteReport(this.reportSiteDTO.reportName)
      this.$watch('singleReport', () => {
        console.log('Report Found', this.singleReport)
      })

      this.fetchDataSourceData(this.reportSiteDTO.dataSources, moment(new Date()).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))


      this.$watch('reportData', () => {
        if(this.singleReport.reportName != null){
          console.log('Report Data in build', this.reportSiteDTO, this.singleReport.reportStyle.reportConfig)
          this.reportStyle = this.singleReport.reportStyle.reportConfig
          this.reportStyle.dataSource.data = this.reportData
        }
        else{
          this.reportStyle = {
            dataSource: {
              type: 'json',
              data: this.reportData,
            },
            slice: {
              rows: [
                {
                  uniqueName: 'IsActive',
                  filter: {
                    members: [
                      'IsActive.true',
                    ],
                  },
                  sort: 'asc',
                },
              ],
            },
            options: {
              grid: {
                title: this.reportSiteDTO.reportName,
                showTotals: 'off',
                showGrandTotals: 'off',
              }
            }
          }
        }


        this.updateDialog()
      })
    },
    updateType(taskType) {
      this.reportSiteDTO.reportGroup = taskType.display
    },
    updateSource(sourceNames) {
      this.reportSiteDTO.dataSources = sourceNames
      console.log('DataSource', this.reportSiteDTO, sourceNames)
    },
    updateReport(reportConfig) {
      console.log(this.reportSiteDTO, reportConfig, this.reportSiteDTO.reportGroup)
      this.postReport(reportConfig, this.reportSiteDTO)
    },
    updateForm(form){
      this.formName = form.formName
      this.reportSiteDTO.dataSources = this.reportSiteDTO.dataSources + "|" + this.formName
      console.log('Form Select', form, this.formName, this.reportSiteDTO.dataSources)
    }
  },
}
</script>
