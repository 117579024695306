<template>
  <v-card id="report-list">
    <!-- search -->
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <div class="d-flex align-center pb-5">
        <!-- create report -->
        <v-btn
          color="primary"
          class="me-3"
          @click="isEdit = true, updateDialog('edit')"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          <span>Create Report</span>
        </v-btn>
      </div>

      <v-spacer></v-spacer>

      <div class="d-flex align-center pb-5">
        <task-type-select
          :multiple="false"
          @task-type-select="updateType"
        ></task-type-select>
      </div>
    </v-card-text>

    <!-- table -->
    <v-data-table
      v-model="selectedRow"
      :headers="tableColumns"
      :items="reportList"
      :options.sync="options"
      :loading="loading"
      class="text-no-wrap"
      group-by="reportStyle.reportGroup"
      :group-desc="true"
    >
      <!-- actions -->
      <template #[`item.actions`]="{item}">
        <v-menu
          bottom
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="selectRow(item)"
            >
              <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item link>
              <v-btn
                text
                x-small
                @click="isEdit = true, updateDialog('edit')"
              >
                <v-icon
                  size="20"
                  class="me-2"
                >
                  {{ icons.mdiPencilOutline }}
                </v-icon>
                <span>Edit</span>
              </v-btn>
            </v-list-item>

            <v-list-item link>
              <v-btn
                text
                x-small
                @click="isEdit = false, updateDialog('render')"
              >
                <v-icon
                  size="20"
                  class="me-2"
                >
                  {{ icons.mdiEyeOutline }}
                </v-icon>
                <span>View</span>
              </v-btn>
            </v-list-item>

            <v-list-item link>
              <v-btn
                text
                x-small
                @click="isEdit = true, updateDialog('render')"
              >
                <v-icon
                  size="20"
                  class="me-2"
                >
                  {{ icons.mdiContentCopy }}
                </v-icon>
                <span>Duplicate</span>
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>


    <v-dialog
      v-model="loading"
      persistent
      max-width="95%"
    >
      <v-skeleton-loader
        v-if="loading"
        type="table-heading, list-item-two-line"
      ></v-skeleton-loader>
    </v-dialog>


    <v-dialog
      v-model="renderDialog"
      persistent
      max-width="95%"
    >
      <div>
          <v-card>
            <report-render
              v-if="renderDialog"
              :data="selectedReport.reportConfig.dataSource"
              :name="selectedReport.reportName"
              :report-style="selectedReport.reportConfig"
              :source="selectedReport.dataSources"
              :form="formName"
              @update-dialog="updateDialog"
            ></report-render>
        </v-card>
      </div>
    </v-dialog>

    <v-dialog
      v-model="editDialog"
      persistent
      max-width="75%"
    >
      <div>
        <v-card>
          <report-add-edit
            v-if="editDialog"
            :is-report-form-active="editDialog"
            :report-site-d-t-o="selectedReport"
            :is-edit="isEdit"
            @update-dialog="updateDialog"
          ></report-add-edit>
        </v-card>
      </div>
    </v-dialog>
  </v-card>
</template>

<script>
import {
  mdiCloudPrintOutline,
  mdiContentCopy,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiPencilOutline,
  mdiPlus,
  mdiTrendingUp,
} from '@mdi/js'

import reportService from '@/services/ReportService'
import useReportService from '@/store/useReportService'

import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import ReportRender from '@/apps/report/ReportRender.vue'
import ReportAddEdit from '@/apps/report/ReportAddEdit.vue'
import TaskTypeSelect from '@/components/task/TaskTypeSelect.vue'
import moment from 'moment'

export default {
  components: {
    ReportRender,
    ReportAddEdit,
    TaskTypeSelect,
  },
  data: () => ({
    renderDialog: false,
    editDialog: false,
    isEdit: false,
    formName: null,
  }),
  setup() {
    const REPORT_APP = 'app-report'

    // Register module
    if (!store.hasModule(REPORT_APP)) {
      store.registerModule(REPORT_APP, reportService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REPORT_APP)) {
        store.unregisterModule(REPORT_APP)
      }
    })

    const { fetchReports, reportList, groupFilter, loading, tableColumns, searchQuery, selectedRow, options, fetchDataSourceData, reportData } = useReportService()

    const actionOptions = [
      { title: 'Edit', icon: mdiPencilOutline },
      { title: 'View', icon: mdiEyeOutline },
      { title: 'Duplicate', icon: mdiContentCopy },
    ]

    const selectedReport = ref({})

    return {
      actionOptions,
      selectedReport,

      fetchReports,
      fetchDataSourceData,
      reportList,
      reportData,
      groupFilter,
      loading,
      tableColumns,
      searchQuery,
      selectedRow,
      options,

      icons: {
        mdiTrendingUp,
        mdiPlus,
        mdiCloudPrintOutline,
        mdiDotsVertical,
        mdiEyeOutline,
        mdiContentCopy,
        mdiPencilOutline,
      },
    }
  },
  mounted() {
    this.fetchReports()
    this.$watch('reportList', () => {
      console.log(this.reportList)
    })
  },
  methods: {
    log(msg) {
      console.log(msg)
    },
    selectRow(item) {
      console.log('Row', item, item.reportStyle.reportConfig)
      this.selectedReport = item.reportStyle
      console.log('selected Report', this.selectedReport)

      if(this.selectedReport.dataSources.split('|')[0] == 'PatientForm'){
        this.formName = this.selectedReport.dataSources.split('|')[1]
        console.log('FormName', this.formName, this.selectedReport.dataSources)
      }


    },
    updateDialog(dialog) {
      if (dialog === 'edit') {
        console.log('Dialog', this.editDialog)
        this.isEdit = true
        if (this.editDialog) {
          this.selectedReport = {}
        }
        console.log('Selected Edit Report', this.selectedReport)
        this.editDialog = !this.editDialog
      } else if (!this.renderDialog) {
        this.fetchDataSourceData(this.selectedReport.dataSources, moment(new Date()).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))
        this.$watch('reportData', () => {
          console.log(this.reportData)
          this.selectedReport.reportConfig.dataSource.data = this.reportData
          console.log('Selected Report', this.selectedReport.reportConfig.dataSource.data)
          this.renderDialog = !this.renderDialog
        })
      } else if (this.renderDialog) {
        this.renderDialog = !this.renderDialog
      }
    },
    updateType(item) {
      this.groupFilter = []
      if (!this.groupFilter.includes(item.display)) {
        this.groupFilter.push(item.display)
      }
      console.log('Group', this.groupFilter)
    },
  },
}
</script>

<style lang="scss" scoped>
#form-list {
  .form-list-actions {
    max-width: 7.81rem;
  }
  .form-list-search {
    max-width: 10.625rem;
  }
  .form-list-status {
    max-width: 11.3rem;
  }
  .form-list-category {
    max-width: 12.3rem;
  }
}
</style>
