import store from '@/store'
import { mdiChartTimelineVariant, mdiCheckCircleOutline, mdiClose } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import moment from 'moment'

export default function useFormList() {
  const formList = ref([])
  const formAddendumList = ref([])
  const formInputList = ref([])
  const recentList = ref([])
  const patientFormList = ref([])
  const formData = ref({
    formConfig: {},
    sections: {},
    rows: {},
    controls: {},
  })
  const formInput = ref({})

  const siteFormData = ref({})
  const pdfList = ref([])
  const tbList = ref([])
  const postPDFResponse = ref(null)

  const formAddendumColumns = [
    { text: 'VALUE', value: 'value' },
    { text: 'RECORDED BY', value: 'recordedBy' },
    { text: 'RECORDED DATE', value: 'recordedDate' },
  ]
  const tableColumns = [
    { text: 'NAME', value: 'formName' },
    { text: 'FlowSheet', value: 'formStyle.flowsheet' },
    { text: 'GROUP', value: 'formStyle.formGroup' },
    { text: 'CREATED', value: 'modifiedDate' },
    { text: 'CREATED BY', value: 'modifiedBy' },
    { text: 'ACTIVE', value: 'isActive' },
    { text: 'VISIBLE', value: 'isVisible' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]
  const patientFormColumns = [
    { text: 'NAME', value: 'formStyle.formName' },
    { text: 'FORM TYPE', value: 'formStyle.formGroup' },
    { text: 'CREATED', value: 'modifiedDate' },
    { text: 'CREATED BY', value: 'modifiedBy' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const patientDocumentColumns = [
    { text: 'NAME', value: 'docTitle' },
    { text: 'NOTE', value: 'note' },
    { text: 'CATEGORY', value: 'category' },
    { text: 'RECORDED', value: 'recorded' },
    { text: 'CREATED BY', value: 'modifiedBy' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const patientTBColumns = [
    { text: 'SITE', value: 'administrationSite' },
    { text: 'DATE', value: 'recordedDate' },
    { text: 'ADMIN BY', value: 'recordedBy' },
    { text: 'READ', value: 'read' },
    { text: 'POSITIVE', value: 'positive' },
    { text: 'READ DATE', value: 'modifiedDate' },
    { text: 'READ BY', value: 'modifiedBy' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const searchQuery = ref('')
  const statusFilter = ref(null)
  const groupFilter = ref([])
  const callResponse = ref(null)
  const totalFormList = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['formName'],
    groupBy: ['formStyle.formGroup'],
    sortDesc: [false],
    itemsPerPage: 5
  })
  const docOptions = ref({
    sortBy: ['recordedDate'],
    groupBy: ['category'],
    sortDesc: [false],
    itemsPerPage: 5
  })
  const tbOptions = ref({
    sortBy: ['recordedDate'],
    groupBy: ['read'],
    sortDesc: [false],
    itemsPerPage: 5
  })
  const siteFormTotalLocal = ref([])
  const selectedRow = ref([])
  const selectedForm = ref(null)

  const formatDate = date => {
    let dates = moment(String(date)).format('YYYY-MM-DD')

    if (dates === 'Invalid date') {
      dates = null
    }

    return dates
  }

  // fetch data
  const fetchForms = () => {
    loading.value = true
    store
      .dispatch('app-form/fetchForms')
      .then(response => {
        formList.value = []

        if (groupFilter.value.length !== 0) {
          groupFilter.value.forEach(group => {
            formList.value = response.filter(form => form.formStyle.formGroup === group)
          })
        } else {
          formList.value = response
        }

        // //console.log(formList.value)

        formInputList.value = response.filter(form => {
          if (form.isVisible) {
            return form
          }

          return null
        })

        loading.value = false
      })
      .catch(error => {
        alert(`Error for fetchForms due to ${error}`)
      })
  }

  watch([groupFilter], () => {
    loading.value = true
    selectedRow.value = []
    fetchForms()
  })

  // get data
  const fetchFormConfig = formStyleId => {
    store
      .dispatch('app-form/fetchFormConfig', formStyleId)
      .then(response => {
        formData.value = response

        loading.value = false
      })
      .catch(error => {
        alert(`Error for fetchFormConfig due to ${error}`)
      })
  }

  watch([], () => {
    fetchFormConfig()
  })

  // post data
  const postFormConfig = (formConfig, styleId, status, group, flowsheet) => {
    const formStyleDTO = ref({
      id: styleId,
      formName: formConfig.formConfig.headline,
      formGroup: group,
      formConfiguration: formConfig,
      isActive: status,
      flowsheet
    })
    console.log(formStyleDTO.value)

    store
      .dispatch('app-form/postFormConfig', formStyleDTO.value)
      .then(response => {
        callResponse.value = response

        loading.value = false
      })
      .catch(error => {
        alert(`Error for postFormConfig due to ${error}`)
      })
  }

  watch([], () => {
    postFormConfig()
  })

  // fetch patient forms
  const fetchPatientForms = patientId => {
    loading.value = true
    store
      .dispatch('app-form/fetchPatientForms', patientId)
      .then(response => {
        patientFormList.value = []

        if (groupFilter.value.length !== 0) {
          groupFilter.value.forEach(group => {
            patientFormList.value = response.filter(form => form.formStyle.formGroup === group)
          })
        } else {
          patientFormList.value = response
        }

        patientFormList.value.forEach(element => {
          element.modifiedDate = element.modifiedDate !== null && element.modifiedDate !== undefined ? formatDate(element.modifiedDate) : null
        })
        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([], () => {
    loading.value = true
    fetchPatientForms()
  })

  const fetchRecentPatientForms = (patientId, formName) => {
    const payload = ref({ patientId, formName })
    console.log('Payload', payload.value)
    store
      .dispatch('app-form/fetchRecentPatientForms', payload.value)
      .then(response => {
        recentList.value = response

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        alert(`Error for fetchPatientForms due to ${error}`)
      })
  }

  watch([], () => {
    fetchRecentPatientForms()
  })

  const postPatientForm = patientFormDTO => {
    store
      .dispatch('app-form/postPatientForm', patientFormDTO)
      .then(response => {
        loading.value = false
      })
      .catch(error => {
        alert(`Error for postPatientForm due to ${error}`)
      })
  }

  watch([], () => {
    postPatientForm()
  })

  // Post form Draft
  const postPatientFormDraft = patientFormDTO => {
    store
      .dispatch('app-form/postPatientFormDraft', patientFormDTO)
      .then(response => {
        console.log(response)

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([], () => {
    postPatientFormDraft()
  })

  const deletePatientForm = patientFormId => {
    store
      .dispatch('app-form/deletePatientForm', patientFormId)
      .catch(error => {
        console.log(error)
      })
  }

  watch([], () => {
    deletePatientForm()
  })


  // fetch patient Addendum
  const fetchPatientFormAddendums = patientFormId => {
    console.log('patientFormID', patientFormId)
    store
      .dispatch('app-form/fetchPatientFormAddendums', patientFormId)
      .then(response => {
        console.log(response)
        formAddendumList.value = response

        loading.value = false
      })
      .catch(error => {
        alert(`Error for fetchPatientFormAddendums due to ${error}`)
      })
  }

  watch([], () => {
    fetchPatientFormAddendums()
  })

  const postPatientFormAddendum = patientFormAddendumDTO => {
    patientFormAddendumDTO.isActive = true
    store
      .dispatch('app-form/postPatientFormAddendum', patientFormAddendumDTO)
      .then(response => {
        console.log(response)

        loading.value = false
      })
      .catch(error => {
        alert(`Error for postPatientFormAddendum due to ${error}`)
      })
  }

  watch([], () => {
    postPatientFormAddendum()
  })

  // Patient Documents
  const fetchPatientPDFs = async patientId => {
    loading.value = true
    await store
      .dispatch('app-form/fetchPatientPDFs', patientId)
      .then(response => {
        pdfList.value = response

        loading.value = false
      })
      .catch(error => {
        alert(`error FetchPatientPDFs due to ${error}`)
      })
  }

  watch([], () => {
    loading.value = true
    fetchPatientPDFs()
  })

  const fetchPatientPDF = async itemId => {
    loading.value = true
    await store
      .dispatch('app-form/getPatientPDF', itemId)
      .then(response => {
        console.log('PDF', response)

        loading.value = false
      })
      .catch(error => {
        alert(`error fetchPatientPDF due to ${error}`)
      })
  }

  watch([], () => {
    loading.value = true
    fetchPatientPDF()
  })

  const postPatientPDF = async patientDocumentDTO => {
    await store
      .dispatch('app-form/postPatientPDF', patientDocumentDTO)
      .then(response => {
        postPDFResponse.value = response
        loading.value = false
      })
      .catch(error => {
        alert(`error PostPatientPDF due to ${error}`)
      })
  }

  watch([], () => {
    loading.value = true
    postPatientPDF()
  })

    // Patient TB
    const fetchPatientTBForms = async patientId => {
      loading.value = true
      await store
        .dispatch('app-form/fetchPatientTBForms', patientId)
        .then(response => {
          tbList.value = response

          loading.value = false
        })
        .catch(error => {
          alert(`error FetchPatientTBForms due to ${error}`)
        })
    }

    watch([], () => {
      loading.value = true
      fetchPatientTBForms()
    })

    const fetchPatientTBForm = async itemId => {
      loading.value = true
      await store
        .dispatch('app-form/fetchPatientTBForm', itemId)
        .then(response => {
          tbList.value = response

          loading.value = false
        })
        .catch(error => {
          alert(`error fetchPatientTBForm due to ${error}`)
        })
    }

    watch([], () => {
      loading.value = true
      fetchPatientTBForm()
    })

    const postPatientTBForm = async patientTuberculosisDTO => {
      patientTuberculosisDTO.expirationDate =
      patientTuberculosisDTO.expirationDate !== null && patientTuberculosisDTO.expirationDate !== undefined
        ? new Date(patientTuberculosisDTO.expirationDate)
        : null
      patientTuberculosisDTO.postitiveDate =
      patientTuberculosisDTO.postitiveDate !== null && patientTuberculosisDTO.postitiveDate !== undefined
        ? new Date(patientTuberculosisDTO.postitiveDate)
        : null
      await store
        .dispatch('app-form/postPatientTBForm', patientTuberculosisDTO)
        .then(response => {
          loading.value = false
        })
        .catch(error => {
          alert(`error PostPatientTBForm due to ${error}`)
        })
    }

    watch([], () => {
      loading.value = true
      postPatientTBForm()
    })



  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*


  const errorMarkForm = errorMark => {
    console.log('error mark form', errorMark)
    // Deactivate patient Form
    const patientForm = errorMark.entity
    patientForm.isActive = false
    patientForm.modifiedDate = new Date(patientForm.modifiedDate)
    postPatientForm(patientForm)

    // Create Error Mark Entry
  }

  const resolveFormStatusVariantAndIcon = status => {
    if (status === 'Inactive') return { variant: 'error', icon: mdiChartTimelineVariant }
    if (status === 'Active') return { variant: 'success', icon: mdiCheckCircleOutline }

    return { variant: 'secondary', icon: mdiClose }
  }

  return {
    formList,
    formInputList,
    patientFormList,
    tableColumns,
    patientFormColumns,
    searchQuery,
    statusFilter,
    totalFormList,
    loading,
    options,
    siteFormTotalLocal,
    selectedRow,
    siteFormData,
    selectedForm,
    formData,
    formInput,
    callResponse,

    deletePatientForm,
    errorMarkForm,

    fetchForms,

    fetchFormConfig,
    fetchPatientForms,
    postPatientForm,
    groupFilter,

    postFormConfig,

    fetchPatientFormAddendums,
    postPatientFormAddendum,
    formAddendumList,
    formAddendumColumns,

    resolveFormStatusVariantAndIcon,

    fetchRecentPatientForms,
    recentList,

    postPatientFormDraft,

    fetchPatientPDF,
    fetchPatientPDFs,
    postPatientPDF,
    pdfList,
    patientDocumentColumns,
    postPDFResponse,
    docOptions,

    fetchPatientTBForm,
    fetchPatientTBForms,
    postPatientTBForm,
    tbList,
    patientTBColumns,
    tbOptions,
  }
}
