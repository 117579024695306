<template>
  <div id="source-select">
    <v-combobox
      v-model="selectedDataSource"
      :items="dataSourceList"
      item-text="display"
      outlined
      label="Select Data Source"
      clearable
      dense
      @click="log(sources)"
      @change="selectDataSource(selectedDataSource)"
    >
    </v-combobox>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { required } from '@core/utils/validation'

import reportService from '@/services/ReportService'
import useReportService from '@/store/useReportService'

export default {
  props: {
    sources: {
      type: Array,
      default: null,
      required: false,
    },
  },
  setup(props, { emit }) {
    const REPORT_APP = 'app-report'

    // Register module
    if (!store.hasModule(REPORT_APP)) {
      store.registerModule(REPORT_APP, reportService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REPORT_APP)) {
        store.unregisterModule(REPORT_APP)
      }
    })

    const { dataSourceList, fetchDataSources } = useReportService()

    const selectedDataSource = ref(props.sources)

    const log = msg => {
      console.log(msg)
    }

    const selectDataSource = () => {
      emit('source-select', selectedDataSource.value)
    }

    return {
      dataSourceList,
      selectedDataSource,
      fetchDataSources,

      required,
      log,

      selectDataSource,
    }
  },
  mounted() {
    this.fetchDataSources()
  },
}
</script>
